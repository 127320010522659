<template>
  <b-modal id="ActionsData" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/actions-data.svg" class="icon-lg" />
        {{ $t("actionsData.data") }}
      </h3>
    </template>
    <div class="">
      <div class="my-card">
        <span class="my-card-title">{{ $t("actionsData.add") }}</span>

        <div class="row">
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="actionsData.userCreatedData.userNameCurrent"
            :title="$t('actionsData.user')"
            :imgName="'man.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="
              formateDateTimeLang(
                actionsData.creationDate,
                actionsData.creationTime
              )
            "
            :title="$t('actionsData.dateTime')"
            :imgName="'dateAndTime.svg'"
          />
        </div>
      </div>

      <div class="my-card">
        <span class="my-card-title">{{ $t("actionsData.edit") }}</span>

        <div class="row">
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="actionsData.userLastUpdatedData.userNameCurrent"
            :title="$t('actionsData.user')"
            :imgName="'man.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="
              formateDateTimeLang(
                actionsData.lastUpdateDate,
                actionsData.lastUpdateTime
              )
            "
            :title="$t('actionsData.dateTime')"
            :imgName="'dateAndTime.svg'"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import { formateDateTimeLang } from "@/utils/functions";

export default {
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["actionsData"],
  methods: {
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
  },
};
</script>

<style scoped lang="scss"></style>
