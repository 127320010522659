import { render, staticRenderFns } from "./BrandInfo.vue?vue&type=template&id=0f2cffbc&scoped=true&"
import script from "./BrandInfo.vue?vue&type=script&lang=js&"
export * from "./BrandInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f2cffbc",
  null
  
)

export default component.exports