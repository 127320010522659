<template>
  <b-modal id="BrandInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/brand.svg" class="icon-lg" />
        {{ $t("brands.data") }}
      </h3>
    </template>
    <div class="">
      <div class="my-card">
        <div class="row">
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="brand.fullCode"
            :title="$t('code')"
            :imgName="'code.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="brand.activityTypeData.activityTypeNameCurrent"
            :title="$t('activityTypes.name')"
            :imgName="'activity-types.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="brand.brandNameAr"
            :title="$t('brands.nameAr')"
            :imgName="'brand.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="brand.brandNameEn"
            :title="$t('brands.nameEn')"
            :imgName="'brand.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="brand.brandDescriptionAr"
            :title="$t('brands.descriptionAr')"
            :imgName="'description.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="brand.brandDescriptionEn"
            :title="$t('brands.descriptionEn')"
            :imgName="'description.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-12'"
            :value="brand.brandDiscountPercentage"
            :title="$t('brands.discountPercentage')"
            :imgName="'maxDiscountPercentage.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-12'"
            :value="brand.brandNotes"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-8'"
            :value="brand.mapLink"
            :title="$t('brands.mapLink')"
            :imgName="'location.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-4'"
            :value="brand.mapLinkStatus == true ? $t('yes') : $t('no')"
            :title="$t('brands.linkStatus')"
            :imgName="'type.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-8'"
            :value="brand.faceBookLink"
            :title="$t('brands.faceBookLink')"
            :imgName="'facebook.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-4'"
            :value="brand.faceBookLinkStatus == true ? $t('yes') : $t('no')"
            :title="$t('brands.linkStatus')"
            :imgName="'type.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-8'"
            :value="brand.twitterLink"
            :title="$t('brands.twitterLink')"
            :imgName="'twitter.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-4'"
            :value="brand.twitterLinkStatus == true ? $t('yes') : $t('no')"
            :title="$t('brands.linkStatus')"
            :imgName="'type.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-8'"
            :value="brand.instagramLink"
            :title="$t('brands.instagramLink')"
            :imgName="'instagram.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-4'"
            :value="brand.instagramLinkStatus == true ? $t('yes') : $t('no')"
            :title="$t('brands.linkStatus')"
            :imgName="'type.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-8'"
            :value="brand.webSiteLink"
            :title="$t('brands.webSiteLink')"
            :imgName="'link.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-4'"
            :value="brand.webSiteLinkStatus == true ? $t('yes') : $t('no')"
            :title="$t('brands.linkStatus')"
            :imgName="'type.svg'"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import { timeToLang } from "@/utils/functions";

export default {
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["brand"],
  methods: {
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
  },
};
</script>

<style scoped lang="scss"></style>
