import PaginationData from "@/models/general/PaginationData";
import Brand from "./Brand";
import BrandsFilter from "./BrandsFilter";

export default class Brands {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.brandsData = [];
    this.brand = new Brand();
    this.filterData = new BrandsFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.pagination);
    this.brandsData = data.brandsData;
  }
}
