<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="brands.brandsData">
      <div class="row">
        <BrandCard
          v-for="(brand, index) in brands.brandsData"
          :key="index"
          :defaultImg="brands.brand.defaultImg"
          v-on:setBrandData="brands.brand.fillData($event)"
          :brand="brand"
        />
      </div>

      <ActionsData :actionsData="brands.brand" />
      <BrandInfo :brand="brands.brand" />
      <BrandDelete :brand="brands.brand" v-on:refresh="getAllBrands()" />

      <Pagination
        v-if="!isLoading"
        :paginationData="brands.pagination"
        v-on:changePage="changePage"
      />
    </div>

    <BrandFloatBtns
      :theFilterData="brands.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import createToastMixin from "@/utils/create-toast-mixin";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import ActionsData from "@/components/general/ActionsData.vue";
import BrandCard from "@/components/brands/BrandCard.vue";
import BrandInfo from "@/components/brands/BrandInfo.vue";
import BrandDelete from "@/components/brands/BrandDelete.vue";
import BrandFloatBtns from "@/components/brands/BrandFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import Brands from "@/models/brands/Brands";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    ActionsData,
    BrandCard,
    BrandInfo,
    BrandDelete,
    BrandFloatBtns,
    Pagination,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  props: ["activityTypeToken"],
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      brands: new Brands(),
    };
  },
  methods: {
    changePage(page) {
      this.brands.pagination.selfPage = page;
      this.getAllBrands();
    },
    search(data) {
      this.brands.filterData.fillData(data);
      this.brands.brandsData = [];
      this.getAllBrands();
    },
    async getAllBrands() {
      this.isLoading = true;
      try {
        const response = await this.brands.brand.getAllBrands(
          this.language,
          this.userAuthorizeToken,
          this.brands.pagination,
          this.brands.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.brands.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.brands.brandsData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.brands.brandsData = null;
          this.$store.dispatch("logoutUser");
        } else {
          this.brands.brandsData = null;
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.brands.brandsData = null;
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
    setFilter() {
      this.brands.filterData.activityTypeToken = this.activityTypeToken;
    },
  },
  async created() {
    this.setFilter();
    this.getAllBrands();
  },
};
</script>
