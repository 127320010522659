<template>
  <div class="flip-card col-12 col-sm-6 col-md-4 col-lg-3">
    <div class="flip-card-inner">
      <div class="flip-card-front">
        <img :src="imgSrc" :onerror="`this.src='${defaultImg}'`" alt="Image" />
        <div class="flip-card-title">
          <h4 class="cut-1line" :title="brand.brandNameCurrent">
            {{ isDataExist(brand.brandNameCurrent) }}
          </h4>
        </div>
      </div>
      <div class="flip-card-back">
        <div class="flip-card-title-top">
          <h6 class="cut-1line">
            {{ isDataExist(brand.fullCode) }}
          </h6>
        </div>
        <div class="flip-card-back-icons">
          <button
            v-b-modal.BrandInfo
            class="btn p-0"
            :title="$t('info')"
            @click="setBrandData()"
          >
            <img src="@/assets/images/info.svg" class="icon-lg" />
          </button>
          <router-link
            v-if="
              checkPrivilege(usersPrivilegeData.usersPrivilegeBrands.update)
            "
            :to="{
              name: 'BrandEdit',
              params: {
                brandToken: brand.brandToken,
              },
            }"
            :title="$t('edit')"
          >
            <img src="@/assets/images/pencil.svg" class="icon-lg" />
          </router-link>
          <router-link
            :to="{
              name: 'BrandServices',
              params: {
                activityTypeToken: brand.activityTypeToken,
                brandToken: brand.brandToken,
              },
            }"
            :title="$t('brandServices.modelName')"
          >
            <img src="@/assets/images/brand-services.svg" class="icon-lg" />
          </router-link>
          <router-link
            :to="{
              name: 'BrandPlaces',
              params: {
                activityTypeToken: brand.activityTypeToken,
                brandToken: brand.brandToken,
              },
            }"
            :title="$t('brandPlaces.modelName')"
          >
            <img src="@/assets/images/brand-places.svg" class="icon-lg" />
          </router-link>
          <button
            v-if="
              checkPrivilege(usersPrivilegeData.usersPrivilegeBrands.archive)
            "
            v-b-modal.BrandDelete
            class="btn p-0"
            :title="$t('delete')"
            @click="setBrandData()"
          >
            <img src="@/assets/images/trash.svg" class="icon-lg" />
          </button>
          <button
            v-if="
              checkPrivilege(usersPrivilegeData.usersPrivilegeBrands.update)
            "
            v-b-modal.ActionsData
            class="btn p-0"
            :title="$t('actionsData.modelName')"
            @click="setBrandData()"
          >
            <img src="@/assets/images/actions-data.svg" class="icon-lg" />
          </button>
        </div>
        <div class="flip-card-title">
          <h4 class="cut-1line" :title="brand.brandNameCurrent">
            {{ isDataExist(brand.brandNameCurrent) }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { checkPrivilege } from "@/utils/functions";
import { BASE_URL } from "@/utils/constants";
import { isDataExist } from "@/utils/functions";

export default {
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["brand", "defaultImg"],
  methods: {
    setBrandData() {
      this.$emit("setBrandData", this.brand);
    },
    isDataExist,
    checkPrivilege,
  },
  computed: {
    ...mapGetters(["usersPrivilegeData"]),
    imgSrc: function () {
      return BASE_URL + this.brand.brandImagePath;
    },
  },
};
</script>

<style lang="scss"></style>
